
import "cropperjs/dist/cropper.css";
import { ContentLoader } from "vue-content-loader";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  getAdd,
  getView
} from "@/services/UsuarioService";
import { useStore } from "vuex";

export default defineComponent({
  name: "adicionarUsuario",

  components: {
    ContentLoader,
  },

  setup(_props, { emit }) {
    const loadingButton = ref(false)
    const formRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const codUsuario = route.params.codUsuario ? Number(route.params.codUsuario) : null;
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const codNivel: any = ref([]);
    let loading = ref(false);

    let formUsuario: any = reactive({
      email: "",
      nome: "",
      senha: "",
      codNivel: "",
      programaAgenda: 1
    });    

    const programarQuadro = [
      {
        text: 'Não',
        value: 0
      },
      {
        text: 'Sim',
        value: 1
      }
    ]
    const validatePassword = (rule: any, formUsuario: any, callback: any) => {
      let password = formUsuario;

      if (password.length > 20) {
        return callback(new Error("limite de 20 caracteres"));
      } else if (password.length <= 0) {
        return callback(new Error("Digite uma senha válida"));
      }
      return true;
    };

    const validateEmail = (rule: any, formUsuario: any, callback: any) => {
      let usuario = formUsuario.substring(0, formUsuario.indexOf("@"));
      let dominio = formUsuario.substring(
        formUsuario.indexOf("@") + 1,
        formUsuario.length
      );     
      if (
        usuario.length >= 1 &&
        dominio.length >= 3 &&
        usuario.search("@") == -1 &&
        dominio.search("@") == -1 &&
        usuario.search(" ") == -1 &&
        dominio.search(" ") == -1 &&
        dominio.search(".") != -1 &&
        dominio.indexOf(".") >= 1 &&
        dominio.lastIndexOf(".") < dominio.length - 1
      ) {
        return true;
      } else {
        return callback(new Error("Favor inserir email válido"));
      }
    };

    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const dadosUsuarioespecifico = ref({});
    const buscarcodNivelusuario = [
      {
        text: "Administrador",
        value: -1,
      },
      {
        text: distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda",
        value: 1,
      },
      {
        text: "Consultor",
        value: 2,
      },
      {
        text: "Orçamentista",
        value: 3,
      },
      {
        text: "Controlador de quadro",
        value: 4,
      },
      {
        text: "Visualizador de quadro",
        value: 5,
      },
    ];

    const rules = ref({
      nome: [
        {
          required: true,
          message: "Digite um nome válido",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          validator: validateEmail,
          trigger: "change",
        },
      ],
      codNivel: [
        {
          required: true,
          message: "Selecione um nível válido",
          trigger: "change",
        },
      ],
      senha: [
        {
          required: true,
          validator: validatePassword,
          trigger: "change",
        },
      ],
      programaAgenda: [
        {
          required: true,
          message: "Selecione uma opção",
          trigger: "change",
        }
      ]
    });

    onMounted(async () => {
      if (codUsuario) {
        getUsuario(codUsuario);
      }
    });

    async function getUsuario(codUsuario) {
      loading.value = true;
      let response = await getView(codUsuario);
      formUsuario.email = response.email;
      formUsuario.senha = response.senha;
      formUsuario.nome = response.nome;
      formUsuario.codNivel = response.codNivel;
      formUsuario.codUsuario = response.codUsuario;
      formUsuario.programaAgenda = response.programaAgenda;      
      loading.value = false;
    }

    const submit = () => {
      if (!formRef.value) return;
      formRef.value.validate((valid) => {
        if (valid) {
          const postData = {
            codUsuario: formUsuario.codUsuario,
            email: formUsuario.email,
            senha: formUsuario.senha,
            nome: formUsuario.nome,
            codNivel: formUsuario.codNivel,
            programaAgenda: formUsuario.programaAgenda
          };
          salvarUsuario(postData);
          emit("addTask", postData);
        }
      });
    };

    async function salvarUsuario(postData) {
      loadingButton.value = true
      await getAdd(postData).then(() => {
        if (codUsuario) {
          showTimeAlert("Usuário editado com sucesso");
        } else {
          showTimeAlert("Usuário cadastrado com sucesso");
        }
        router.push("/cadastros/usuario/list");
      }).catch(e => {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      });
      loadingButton.value = false;
    }

    function goTo(url) {
      router.push(url);
    }

    return {
      goTo,
      loading,
      formUsuario,
      rules,
      formRef,
      submit,
      codUsuario,
      buscarcodNivelusuario,
      codNivel,
      dadosUsuarioespecifico,
      validateEmail,
      loadingButton,
      programarQuadro,
      distribuidor
    };
  },
});
